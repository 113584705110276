import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {

    const initialFormValues = {
        type: 'Company',
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phoneNumber: '',
        howYouKnow: '',
        message: ''
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Phone number validation
        // const phoneRegex = /^[0-9]{10}$/;
        // if (!formValues.phoneNumber.match(phoneRegex)) {
        //     newErrors.phoneNumber = 'Please enter a valid phone number';
        //     isValid = false;
        // }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formValues.email.match(emailRegex)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }

        if (!formValues.firstName) {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }
        
        if (!formValues.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
            isValid = false;
        }

        if (!formValues.message) {
            newErrors.message = 'This field is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission logic here
            const data = new FormData();
            data.append('firstName', formValues.firstName);
            data.append('lastName', formValues.lastName);
            data.append('email', formValues.email);
            data.append('phoneNumber', formValues.phoneNumber);
            data.append('companyName', formValues.companyName);
            data.append('howYouKnow', formValues.howYouKnow);
            data.append('message', formValues.message);
            const config ={
                method: 'post',
                url: 'https://api.gammastudio.io/api/contact-us',
                headers: {
                    "Content-Type": "application/json"
                },
                data: data
            }
            axios(config).then((response)=>{
                toast.success("Your massage is submitted", {
                    position: "top-center",
                    hideProgressBar: true,
                    theme: "dark",
                  });
            })
            .catch((error)=>{
                toast.error(error.response.data.error, {
                    position: "top-center",
                    hideProgressBar: true,
                    theme: "dark",
                  });
            })

            // Reset form values to initial state
            setFormValues(initialFormValues);
            setErrors({});
        }
    };

    return <>
        <section id="contactUs" className="contact">

            <h1 className="contact__title">
                STAY IN CONTACT
            </h1>
            <h2 className="contact__subtitle">
                Get in Touch
            </h2>

            <form onSubmit={handleSubmit}>
                <div className="contact__flex">
                    <label className="contact__label--flex">
                        <input
                            type="radio"
                            name="type"
                            value="Company"
                            checked={formValues.type === 'Company'}
                            onChange={handleChange}
                        />
                        <p>
                            Company
                        </p>
                    </label>
                    <label className="contact__label--flex">
                        <input
                            type="radio"
                            name="type"
                            value="Personal"
                            checked={formValues.type === 'Personal'}
                            onChange={handleChange}
                        />
                        <p>
                            Personal
                        </p>
                    </label>
                </div>

                <div className="contact__flex--input">
                    <label>
                        <input type="text" placeholder="First Name" name="firstName" value={formValues.firstName} onChange={handleChange} />
                        {errors.firstName && <p>{errors.firstName}</p>}
                    </label>
                    <label>
                        <input type="text" placeholder="Last Name" name="lastName" value={formValues.lastName} onChange={handleChange} />
                        {errors.lastName && <p>{errors.lastName}</p>}
                    </label>
                </div>

                <div className="contact__flex--input">

                    <label>
                        <input type="email" placeholder="Email" name="email" value={formValues.email} onChange={handleChange} />
                        {errors.email && <p>{errors.email}</p>}
                    </label>
                    {formValues.type === 'Company' && (
                        <label>
                            <input type="text" placeholder="Company Name" name="companyName" value={formValues.companyName} onChange={handleChange} />
                            {errors.companyName && <p>{errors.companyName}</p>}
                        </label>
                    )}
                </div>

                <div className="contact__flex--input">
                    <label>
                        <input type="tel" placeholder="Phone Number" name="phoneNumber" value={formValues.phoneNumber} onChange={handleChange} />
                        {errors.phoneNumber && <p>{errors.phoneNumber}</p>}
                    </label>
                    <label>
                        <input type="text" placeholder="How You Know Us?" name="howYouKnow" value={formValues.howYouKnow} onChange={handleChange} />
                        {errors.howYouKnow && <p>{errors.howYouKnow}</p>}
                    </label>
                </div>

                <label>
                    <textarea name="message" placeholder="MESSAGE" value={formValues.message} onChange={handleChange} />
                    {errors.message && <p style={{color: '#ff00008f'}}>{errors.message}</p>}
                </label>
                
                <button type="submit" className="contact__submit">Submit</button>
                <ToastContainer />

            </form>
        </section>
    </>
}