import { useMemo } from "react"

export default function Partners(){
    const partners = useMemo(()=>{
        return [
            "images/partners/aws.png",
            "images/partners/azure.png",
            "images/partners/huawei.png",
        ]
    }, [])
    return <>
    <section className="partners">
        <h1 className="title partners__title">
            Our Partners
        </h1>
        <div className="partners__flex">
            {
                partners.map((item, key)=>{
                    return <img src={item} alt="partner" key={key}/>
                })
            }
        </div>
    </section>
    </>
}