import { motion } from 'framer-motion';
import Header from './Header.jsx';
import Content from './About/Content.jsx';
import Team from './About/Team.jsx';
import Part from './About/Part.jsx';
import Footer from './Footer.jsx';

export default function About() {

    window.scrollTo(0, 0)

    return <motion.div
        className="page__wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <Header inAboutUs={true} />
        <Content />
        {/* <Team /> */}
        <Part />
        <Footer />
    </motion.div>

}