import { Suspense, useEffect, useRef, useState } from "react";

export default function Content() {
  const [isClicked, setIsClicked] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const isMobile = matchMedia("(max-width: 900px)").matches;

  const content = useRef();
  const text = useRef();
  const button = useRef();

  const handleClick = () => {
    const team = document.getElementById("team");
    team.scrollIntoView({ behavior: "smooth" });
    setIsClicked(!isClicked);
  };

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const video = document.getElementById("video");
    const req = new XMLHttpRequest();
    req.open("GET", "videos/About/villa.mp4", true);
    req.responseType = "blob";
    req.onload = function () {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        video.src = vid;
        video.play();
        onLoadedData();
      }
    };
    req.onerror = function () {
      // Error
    };

    req.send();
  }, []);
  return (
    <>
      <section ref={content} className="content">
        {
          !isMobile && <>
          
          <img
            src="/videos/About/video-blur.jpg"
            alt="blured image villa"
            className="content__video"
            style={{
              width: "100%",
              opacity: isVideoLoaded ? 0 : 1,
            }}
          />
          <video
            id="video"
            className="content__video"
            muted
            loop
            playsInline
            preload="auto"
            style={{ opacity: isVideoLoaded ? 1 : 0 }}
          />
          </>
        }
        <div className="content__mask" />
        <div ref={text} className="content__text">
          <h1 className="content__text--title">ABOUT US</h1>
          <p className="content__text--text">
            we are a dynamic and innovative team dedicated to providing
            exceptional 3D visualization solutions. With a passion for
            excellence, we strive to deliver cutting-edge products and services
            tailored to meet the unique needs of our clients. With a diverse
            team of highly skilled professionals, we bring together a wealth of
            knowledge and experience. Our collaborative approach fosters
            creativity and ensures that every project we undertake is met with
            strategic thinking, attention to detail, and a focus on delivering
            measurable value. Client satisfaction is our top priority, and we
            pride ourselves on building strong, long-lasting partnerships. We
            listen to our clients' goals and challenges, working closely with
            them to develop customized solutions that drive growth and Success
          </p>

          <button
            onClick={handleClick}
            ref={button}
            className="content__button"
          >
            <span className="content__button--icon" />
          </button>
        </div>
      </section>
    </>
  );
}
