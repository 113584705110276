import { useState } from "react";
import { Link } from "react-router-dom";

export default function Showcases() {
  const [isClicked, setIsClicked] = useState(false);

  const images = [
    {
      image: "https://img.youtube.com/vi/pnE-GqK5zoE/maxresdefault.jpg",
      name: "Explore the future of real estate showcases with Unreal Engine technology",
      link: "https://www.youtube.com/watch?v=pnE-GqK5zoE",
    },
    {
      image: "https://img.youtube.com/vi/m-5rkGvtyxk/maxresdefault.jpg",
      name: "Experience how Unreal Engine transforms the ambiance of this forest house",
      link: "https://www.youtube.com/watch?v=m-5rkGvtyxk",
    },
    {
      image: "https://img.youtube.com/vi/FMGIQ9QHUOQ/maxresdefault.jpg",
      name: "Discover the immersive virtual tour of this residential project powered by Unreal Engine",
      link: "https://www.youtube.com/watch?v=FMGIQ9QHUOQ",
    },
    {
      image: "https://img.youtube.com/vi/nxmndz5ilvA/maxresdefault.jpg",
      name: "Sultan Ahmet square looks amazing with your unreal engine development",
      link: "https://www.youtube.com/watch?v=nxmndz5ilvA",
    },
    {
      image: "https://img.youtube.com/vi/3YZ1xTCDI24/maxresdefault.jpg",
      name: "Explore this immersive visualization for apartment",
      link: "https://www.youtube.com/watch?v=3YZ1xTCDI24",
    },
    {
      image: "https://img.youtube.com/vi/f009xmTaWuk/maxresdefault.jpg",
      name: "Check how Kaaba Looks on virtual with our unreal engine development",
      link: "https://www.youtube.com/watch?v=f009xmTaWuk",
    },
    {
      image: "https://img.youtube.com/vi/STC4_cbxxM8/maxresdefault.jpg",
      name: "Immersive visualization for a museum by unreal engine but it's like the real",
      link: "https://www.youtube.com/watch?v=STC4_cbxxM8",
    },
    {
      image: "https://img.youtube.com/vi/KRn8BJYC3HM/maxresdefault.jpg",
      name: "Explore our immersive showcasing for a jewellery",
      link: "https://www.youtube.com/watch?v=KRn8BJYC3HM",
    },
    {
      image: "https://img.youtube.com/vi/uqfK8wrtYJ0/maxresdefault.jpg",
      name: "Unreal engine Showcasing for Apartment",
      link: "https://www.youtube.com/watch?v=uqfK8wrtYJ0",
    },
    {
      image: "https://img.youtube.com/vi/3hnAnUWKJCw/maxresdefault.jpg",
      name: "A short tour inside the villa project we developed by Unreal engine in Dubai",
      link: "https://www.youtube.com/watch?v=3hnAnUWKJCw",
    },
    {
      image: "https://img.youtube.com/vi/bmlp6r1kxrs/maxresdefault.jpg",
      name: "Dünya Şehir Project",
      link: "https://www.youtube.com/watch?v=bmlp6r1kxrs",
    },
    {
      image: "https://img.youtube.com/vi/-7yPeEu-B7k/maxresdefault.jpg",
      name: "Gamma Cities Lotus",
      link: "https://www.youtube.com/watch?v=-7yPeEu-B7k",
    },
    {
      image: "https://img.youtube.com/vi/OnnsEBX8lPI/maxresdefault.jpg",
      name: "Gamma Cities Boulevard Project",
      link: "https://www.youtube.com/watch?v=OnnsEBX8lPI",
    },
    {
      image: "https://img.youtube.com/vi/gRAGhcg5tzY/maxresdefault.jpg",
      name: "Gamma Cities Khor Fakan",
      link: "https://www.youtube.com/watch?v=gRAGhcg5tzY",
    },
    {
      image: "https://img.youtube.com/vi/wtxdRmHFgjA/maxresdefault.jpg",
      name: "Gamma Cities Metaverse Galata Tower",
      link: "https://www.youtube.com/watch?v=wtxdRmHFgjA",
    },
    {
      image: "https://img.youtube.com/vi/Ipz5seGIrIU/maxresdefault.jpg",
      name: "Star Business office",
      link: "https://www.youtube.com/watch?v=Ipz5seGIrIU",
    },
    {
      image: "https://img.youtube.com/vi/tS6Q13_b6U8/maxresdefault.jpg",
      name: "Cozy Apartment In Paris France",
      link: "https://www.youtube.com/watch?v=tS6Q13_b6U8",
    },
  ];

  const handleClick = (link) => {
    const isMobile = window.matchMedia("(max-width:900px)").matches;
    const element = document.body;
    if (!isClicked) {
      element.style.height = "100vh";
      element.style.overflow = "hidden";
      setTimeout(() => {
        if (isMobile) {
          window.open(link, "_self");
        } else {
          window.open(link, "_blank");
          element.style.height = "";
          element.style.overflow = "";
        }

        setIsClicked(false);
      }, 2000);
    } else {
      element.style.height = "";
      element.style.overflow = "";
    }
    setIsClicked(!isClicked);
  };

  return (
    <>
      {isClicked && (
        <div className="showcases__redirection">
          <h1 className="showcases__redirection--title">
            <span className="showcases__redirection--title-first">
              We are redirecting you
            </span>
          </h1>
          <div className="showcases__redirection--points">
            {Array(6)
              .fill()
              .map((item, key) => {
                return (
                  <div
                    className="showcases__redirection--point"
                    style={{
                      animationDelay: `${key * 0.12}s`,
                    }}
                  ></div>
                );
              })}
          </div>
        </div>
      )}
      <section style={{marginTop: '35px'}} >
      <Link to={"/"} className="pricing__logo">
                            <img src="images/logo.gif" alt="logo" />
                        </Link>
        <h1 className=" title showcases__title">
          <span className="online"></span>
          SHOWCASES
        </h1>

        <div className="showcases__flex" style={{ flexWrap: "wrap", gap: '50px', padding: '20px' }}>
          {images.map((item, key) => {
            return (
              <>
                {item.link ? (
                  <button
                    className="showcases__image--wrapper"
                    style={{ width: "370px", height: '420px', flexWrap: "wrap" }}
                    key={key}
                    onClick={() => handleClick(item.link)}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="showcases__image"
                      style={{
                        height: "330px",
                      }}
                    />
                    <div
                      className="showcases__card"
                      style={{
                        height: "130px",
                        display: "grid",
                        alignItems: "center",
                        alignContent: 'stretch'
                      }}
                    >
                      <h1 className="showcases__card--number">
                        {key > 8 ? "" : "0"}
                        {key + 1}
                      </h1>
                      <h2
                        style={{
                          fontSize: "18px",
                          lineBreak: "auto",
                          letterSpacing: "0.5px",
                          lineHeight: "1.3",
                          fontWeight: 500,
                        }}
                      >
                        {item.name}
                      </h2>
                    </div>
                  </button>
                ) : (
                  <button
                    className="showcases__image--wrapper"
                    key={key}
                    style={{ cursor: "none" }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ cursor: "none" }}
                      className="showcases__image"
                    />
                    <div className="showcases__card" style={{ cursor: "none" }}>
                      <h1 className="showcases__card--number">0{key + 1}</h1>
                      <h2 className="showcases__card--category">
                        {item.category}
                      </h2>
                      <p className="showcases__card__name">{item.name}</p>
                    </div>
                    <div className="showcases__comingSoon">Coming Soon</div>
                  </button>
                )}
              </>
            );
          })}
        </div>
      </section>
    </>
  );
}
