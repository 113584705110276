import { useMemo } from "react"

export default function Clients() {
    const clients = useMemo(() => {
        return [
            "images/clients/1.png",
            "images/clients/2.png",
            "images/clients/3.png",
            "images/clients/4.png",
            "images/clients/5.png",
            "images/clients/6.png",
            "images/clients/7.png",
        ]
    }, [])
    return <>
        <section className="clients">

            <h1 className="title clients__title">
                Our Clients
            </h1>
            <div className="clients__flex">
                {
                    clients.map((item, key) => {
                        return <img src={item} alt="client" key={key} />
                    })
                }
            </div>
        </section>
    </>
}