import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import App from './App.jsx';
import './style.css';

import './Styles/header.css';
import './Styles/footer.css';

import './Styles/Home/hero.css';
import './Styles/Home/services.css';
import './Styles/Home/showcases.css';
import './Styles/Home/clients.css';
import './Styles/Home/partners.css';
import './Styles/Home/contact.css';

import './Styles/About/content.css';
import './Styles/About/team.css';
import './Styles/About/part.css';

import './Styles/Pricing/pricing.css';
import './Styles/Pricing/pricingMobile.css';

const root = createRoot(document.querySelector('#root'))

root.render(
    <Router >
        <App />
    </Router>
)