import { useEffect } from "react";
import { TypeAnimation } from 'react-type-animation';



export default function Hero() {

    

    return <>
        <section className="hero">
            <div className="hero__firstPart">
                <h1 className="hero__title">
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Revolutionize Traditional\nWeb to Web 5.0',

                        ]}
                        wrapper="span"
                        speed={1}

                        style={{ display: 'inline-block' }}
                        repeat={1}
                    />
                    
                </h1>
                <p className="hero__text">
                    Unlocking the potential of 3D worlds, we transform businesses by transitioning them from traditional websites to immersive virtual environments. Experience the power of Web 5.0 and elevate your online presence to new dimensions with our innovative services.
                </p>
                <a href="https://calendly.com/gammastudio" target="_blank" className="hero__link">
                    Contact sales
                </a>
            </div>

            <div className="hero__secondPart">
                <div className="hero__image">
                    <div className="hero__image--desktop">
                        <img src="images/devices/desktop.png" alt="screen" />
                        {/* <img src="images/devices/desktop-image.jpg" alt="screen" className="hero__image--desktop--image" /> */}
                        <video src="videos/Devices/pc.mp4" muted autoPlay playsInline loop alt="screen" className="hero__image--desktop--video" />

                    </div>
                    <div className="hero__image--phone">
                        <img src="images/devices/phone.png" alt="screen" />
                        {/* <img src="images/devices/phone-image.jpg" alt="screen" className="hero__image--phone--image" /> */}
                        <video src="videos/Devices/mobile.mp4" muted autoPlay playsInline loop alt="screen" className="hero__image--phone--video" />
                    </div>
                    <div className="hero__image--tab">
                        <img src="images/devices/tab.png" alt="screen" />
                        {/* <img src="images/devices/tab-image.jpg" alt="screen" className="hero__image--tab--image" /> */}
                        <video src="videos/Devices/tablet.mp4" muted autoPlay playsInline loop alt="screen" className="hero__image--tab--video" />
                    </div>
                </div>
                <div className="hearo__content">
                    <h2 className="hero__subtitle">
                        IMMERSE YOURSELF
                        <br />
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed out once, initially
                                'AnyWhere',
                                2000, // wait 1s before replacing "Mice" with "Hamsters"
                                'AnyTime',
                                2000,
                                'AnyDevice',
                                2000,
                            ]}
                            wrapper="span"
                            speed={5}

                            style={{ fontSize: '2em', display: 'inline-block' }}
                            repeat={Infinity}
                        />
                        <br />
                        With a Simple
                    </h2>
                    <button className="hero__button">
                        Web Link
                    </button>
                </div>
            </div>
        </section>
    </>
}