import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "./components/Home.jsx";
import About from "./components/About.jsx";
import Pricing from "./components/Pricing.jsx";
import Showcases from "./components/Showcases.jsx";

export default function AnimatedRoutes() {
    const location = useLocation();

    return <AnimatePresence initial={false} mode="wait" >

        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/showcases" element={<Showcases />} />
        </Routes>
    </AnimatePresence>


}