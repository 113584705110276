import axios from "axios";
import { useState } from "react";

export default function Part() {

    const initialFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        howYouKnow: '',
        cvFile: null
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            const allowedTypes = ['application/pdf']; // Allowed file types (PDF in this case)

            if (file && allowedTypes.includes(file.type)) {
                setFormValues({
                    ...formValues,
                    [e.target.name]: file
                });
                console.log(file)
                setErrors({
                    ...errors,
                    cvFile: null
                });
            } else {
                setFormValues({
                    ...formValues,
                    [e.target.name]: null // Reset the file if it's not in the allowed format
                });
                // Display an error message or perform other error handling
                setErrors({
                    ...errors,
                    cvFile: 'Please upload a PDF file.'
                });
            }
        } else {
            setFormValues({
                ...formValues,
                [e.target.name]: e.target.value
            });
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Phone number validation
        const phoneRegex = /^[0-9]{10}$/;
        if (!formValues.phoneNumber.match(phoneRegex)) {
            newErrors.phoneNumber = 'Please enter a valid phone number';
            isValid = false;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formValues.email.match(emailRegex)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission logic here
            const data = new FormData();
            data.append('firstName', formValues.firstName);
            data.append('lastName', formValues.lastName);
            data.append('email', formValues.email);
            data.append('phoneNumber', formValues.phoneNumber);
            data.append('file', formValues.cvFile);
            const config ={
                method: 'post',
                url: 'https://api.gammastudio.io/api/join-us',
                headers: {
                    "Content-Type": "application/json"
                },
                data: data
            }
            axios(config).then((response)=>{
                console.log(JSON.stringify(response.data));
            })
            .catch((error)=>{
                console.error(error);
            })

            // Reset form values to initial state
            setFormValues(initialFormValues);
            setErrors({});
        }
    };
    return <>
        <section className="part">
            <h1 className="part__title">
                Be a part of our team
            </h1>

            <form onSubmit={handleSubmit}>

                <div className="contact__flex--input">
                    <label>
                        <input type="text" placeholder="First Name" name="firstName" value={formValues.firstName} onChange={handleChange} />
                    </label>
                    <label>
                        <input type="text" placeholder="Last Name" name="lastName" value={formValues.lastName} onChange={handleChange} />
                    </label>
                </div>

                <div className="contact__flex--input">

                    <label>
                        <input type="email" placeholder="Email" name="email" value={formValues.email} onChange={handleChange} />
                        {errors.email && <p>{errors.email}</p>}
                    </label>

                    <label>
                        <input type="text" placeholder="How You Know Us?" name="howYouKnow" value={formValues.howYouKnow} onChange={handleChange} />
                    </label>
                </div>

                <div className="contact__flex--input">
                    <label>
                        <input type="tel" placeholder="Phone Number" name="phoneNumber" value={formValues.phoneNumber} onChange={handleChange} />
                        {errors.phoneNumber && <p>{errors.phoneNumber}</p>}
                    </label>
                    <label htmlFor="cvFileInput">
                        <div className="part__file">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={50} height={50}>
                                <path d="M8 1V3H16V1H18V3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H6V1H8ZM12 8L8 12H11V16H13V12H16L12 8Z" fill="rgba(234,13,13,1)"></path>
                            </svg>
                            <span className="part__file--text">
                                Upload your cv
                            </span>
                        </div>
                        {errors.cvFile && <p>{errors.cvFile}</p>}

                    </label>
                    <input
                        id="cvFileInput"
                        type="file"
                        name="cvFile"
                        onChange={handleChange}
                        accept=".pdf"
                    />
                </div>


                <button type="submit" className="contact__submit" style={{ marginLeft: "initial" }}>Submit</button>

            </form>
        </section>
    </>
}