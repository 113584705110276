import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";

const Video = ({ src, index, isPlaying, changeIsPlaying }) => {
  const [isClicked, setIsClicked] = useState(false);
  const video = useRef();

  useEffect(() => {
    if (index !== isPlaying) {
      video.current.pause();
      setIsClicked(false);
    }
  }, [isPlaying]);

  const playVideo = () => {
    isClicked ? video.current.pause() : video.current.play();
    setIsClicked(!isClicked);
    changeIsPlaying(index);
  };

  return (
    <>
      <video
        ref={video}
        src={src}
        muted
        playsInline
        loop
        preload="auto"
        className="services__image"
      />
      <div className="services__image--background" />

      <button onClick={playVideo} className="services__image--play">
        {isClicked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={100}
            height={100}
          >
            <path
              d="M15 7C15 6.44772 15.4477 6 16 6C16.5523 6 17 6.44772 17 7V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V7ZM7 7C7 6.44772 7.44772 6 8 6C8.55228 6 9 6.44772 9 7V17C9 17.5523 8.55228 18 8 18C7.44772 18 7 17.5523 7 17V7Z"
              fill="rgba(255,255,255,1)"
            ></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={100}
            height={100}
          >
            <path
              d="M19.376 12.4158L8.77735 19.4816C8.54759 19.6348 8.23715 19.5727 8.08397 19.3429C8.02922 19.2608 8 19.1643 8 19.0656V4.93408C8 4.65794 8.22386 4.43408 8.5 4.43408C8.59871 4.43408 8.69522 4.4633 8.77735 4.51806L19.376 11.5838C19.6057 11.737 19.6678 12.0474 19.5146 12.2772C19.478 12.3321 19.4309 12.3792 19.376 12.4158Z"
              fill="rgba(255,255,255,1)"
            ></path>
          </svg>
        )}
      </button>
    </>
  );
};

export default function Services() {
  const [isPlaying, setIsPLaying] = useState(100);

  return (
    <>
      <section className="services">
        <h1 className=" title services__title">OUR SERVICES</h1>

        <div className="services__flex1">
          <div className="services__content">
            <h2 className="services__subtitle">
              AR - VR MIXED & EXTENDED REALITY
            </h2>
            <p className="services__text">
              Extended Reality (XR) combines Virtual Reality (VR), Augmented
              Reality (AR), and Mixed Reality (MR) to extend human perception
              and interaction beyond the physical world. It seamlessly blends
              digital and physical environments, creating realistic and
              immersive experiences. XR finds applications in various
              industries, simulating real-world scenarios, visualizing complex
              data, and enhancing communication, learning, and work. It
              revolutionizes how we engage, interact, and experience the digital
              realm, enhancing our understanding and interactions in the digital
              age.
            </p>
          </div>
          <div className="services__image--wrapper">
            <Video
              src="videos/Services/vr.mp4"
              index={0}
              isPlaying={isPlaying}
              changeIsPlaying={setIsPLaying}
            />
          </div>
        </div>

        <div className="services__flex2">
          <div className="services__image--wrapper">
            <Video
              src="videos/Services/villaServices.mp4"
              index={1}
              isPlaying={isPlaying}
              changeIsPlaying={setIsPLaying}
            />
          </div>
          <div className="services__content">
            <h2 className="services__subtitle">
              3D, INTERACTIVE, DYNAMIC, WALKTHROUGH REAL TIME ENGINE APPLICATION
            </h2>
            <p className="services__text">
              By leveraging cutting-edge visualization engines, we deliver the
              pinnacle of realism. Widely adopted in Silicon Valley's gaming
              industry and Hollywood's film making, our engine ensures an
              immersive user experience that closely resembles real life. It is
              also known as real-time rendering for interactive walkthroughs.
            </p>
          </div>
        </div>

        <div className="services__flex3">
          <div className="services__content">
            <h2 className="services__subtitle">
              CLOUD RT STREAMING THROUGH GAMMA CITIES PLATFORM
            </h2>
            <p className="services__text">
              We offer cloud streaming on AWS, Azure, and Huawei GPU servers,
              delivering high-quality content to any device worldwide via a web
              browser link without the need for downloads or installations.
              Similar to interactive YouTube videos, our platform eliminates the
              need for heavy file downloads, expensive equipment, and application
              purchases. Gamma Cities is an advanced virtual twin of real-world
              cities, providing users limitless opportunities for creativity,
              exploration, entertainment, connection, and earnings. With a focus
              on immersive interconnected virtual worlds, Gamma Cities ensures
              the highest-quality user experience.
            </p>
          </div>
          <div className="services__image--wrapper">
            <Video
              src="videos/Services/villa.mp4"
              index={2}
              isPlaying={isPlaying}
              changeIsPlaying={setIsPLaying}
            />
          </div>
        </div>
      </section>
    </>
  );
}
