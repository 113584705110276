import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

export default function Showcases() {
    const [isClicked, setIsClicked] = useState(false);

    const images = useMemo(() => {
        return [
            {
                image: "images/showcases/kaaba.jpg",
                category: "Guide",
                name: "Kaaba",
                // link: "Kaaba",
                link: "https://www.youtube.com/watch?v=f009xmTaWuk",
            },
            // {
            //   image: 'images/showcases/galata.jpg',
            //   category: 'HISTORICAL',
            //   name: 'GALATA TOWER',
            //   // link: 'Galata',
            // },
            {
                image: "images/showcases/tiny-hous.png",
                category: "CONSTRUCTION",
                name: "Tiny House",
                // link: "Tinyhouse",
                link: "https://www.youtube.com/watch?v=m-5rkGvtyxk",
            },
            {
                image: "images/showcases/villa.jpg",
                category: "CONSTRUCTION",
                name: "VILLA",
                // link: "Villa",
                link: "https://www.youtube.com/watch?v=3hnAnUWKJCw",
            },
            {
                image: "images/showcases/office.jpg",
                category: "INTERIOR DESIGN",
                name: "OFFICE",
                // link: "Office",
                link: "https://www.youtube.com/watch?v=Ipz5seGIrIU",
            },
            // {
            //     image: "images/showcases/yacht.jpg",
            //     category: "LUXURY",
            //     name: "YACHT",
            //     // link: "Tinyhouse",
            // },
            {
                image: "images/showcases/flat.png",
                category: "CONSTRUCTION",
                name: "Flat",
                // link: "Flat",
                link: "https://youtu.be/uqfK8wrtYJ0?si=SVtLw1fdyDlXwNHd",
            },
        ];
    }, []);

    const handleClick = (link) => {
        const isMobile = window.matchMedia("(max-width:900px)").matches;
        const element = document.body;
        if (!isClicked) {
            element.style.height = "100vh";
            element.style.overflow = "hidden";
            setTimeout(() => {
                if (isMobile) {
                    // window.open(`https://gammacities.com/?reference=${link}`, '_self');
                    window.open(link, "_self");
                } else {
                    // window.open(
                    //     `https://gammacities.com/?reference=${link}`,
                    //     "_blank"
                    // );
                    window.open(link, "_blank");
                    element.style.height = "";
                    element.style.overflow = "";
                }

                setIsClicked(false);
            }, 2000);
        } else {
            element.style.height = "";
            element.style.overflow = "";
        }
        setIsClicked(!isClicked);
    };

    return (
        <>
            {/* {isClicked && (
                <div className="showcases__redirection">
                    <h1 className="showcases__redirection--title">
                        <span className="showcases__redirection--title-first">
                            We are directing you to
                        </span>
                        <span className="showcases__redirection--title-second">
                            Gamma Cities
                        </span>
                    </h1>
                    <p className="showcases__redirection--text">
                        Where our spaces are streamed online
                        <br />
                        Login and enjoy the experience
                    </p>
                    <div className="showcases__redirection--points">
                        {Array(6)
                            .fill()
                            .map((item, key) => {
                                return (
                                    <div
                                        className="showcases__redirection--point"
                                        style={{
                                            animationDelay: `${key * 0.12}s`,
                                        }}
                                    ></div>
                                );
                            })}
                    </div>
                </div>
            )} */}
            {isClicked && (
                <div className="showcases__redirection">
                    <h1 className="showcases__redirection--title">
                        <span className="showcases__redirection--title-first">
                            We are redirecting you
                        </span>
                    </h1>
                    <div className="showcases__redirection--points">
                        {Array(6)
                            .fill()
                            .map((item, key) => {
                                return (
                                    <div
                                        className="showcases__redirection--point"
                                        style={{
                                            animationDelay: `${key * 0.12}s`,
                                        }}
                                    ></div>
                                );
                            })}
                    </div>
                </div>
            )}
            <section className="showcases">
                <h1 className=" title showcases__title">
                    <span className="online"></span>
                    <Link to={"/showcases"}>SHOWCASES</Link>
                </h1>

                <div className="showcases__flex">
                    {images.map((item, key) => {
                        return (
                            <>
                                {item.link ? (
                                    <button
                                        className="showcases__image--wrapper"
                                        key={key}
                                        onClick={() => handleClick(item.link)}
                                    >
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            className="showcases__image"
                                        />
                                        <div className="showcases__card">
                                            <h1 className="showcases__card--number">
                                                0{key + 1}
                                            </h1>
                                            <h2 className="showcases__card--category">
                                                {item.category}
                                            </h2>
                                            <p className="showcases__card__name">
                                                {item.name}
                                            </p>
                                        </div>
                                    </button>
                                ) : (
                                    <button
                                        className="showcases__image--wrapper"
                                        key={key}
                                        style={{ cursor: "none" }}
                                    >
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            style={{ cursor: "none" }}
                                            className="showcases__image"
                                        />
                                        <div
                                            className="showcases__card"
                                            style={{ cursor: "none" }}
                                        >
                                            <h1 className="showcases__card--number">
                                                0{key + 1}
                                            </h1>
                                            <h2 className="showcases__card--category">
                                                {item.category}
                                            </h2>
                                            <p className="showcases__card__name">
                                                {item.name}
                                            </p>
                                        </div>
                                        <div className="showcases__comingSoon">
                                            Coming Soon
                                        </div>
                                    </button>
                                )}
                            </>
                        );
                    })}
                </div>
            </section>
        </>
    );
}
