import { motion } from "framer-motion";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PricingMobile from "./Pricing/PricingMobile.jsx";

export default function Pricing() {
    const [isClicked, setIsClicked] = useState(false);
    const [planSelected, setPlanSelect] = useState(2);
    const firstPlan = useRef(null);
    const secondPlan = useRef(null);
    const free = useRef(null);
    const isMobile = window.matchMedia("(max-width: 1000px)").matches;

    const initialValues = {
        squarMeter: 100,
        objects: 15,
        interactiveMesh: 2,
        interactiveMaterial: 2,
        buildSurroundingArea: false,
        customView: false,
        businessListing: false,
    }

    const freePackaging = {
        squarMeter: 200,
        objects: 15,
        interactiveMesh: null,
        interactiveMaterial: null,
        buildSurroundingArea: false,
        customView: false,
        businessListing: false,
    }

    const [pricingValue, setPricingValue] = useState(initialValues);
    const [finalPrice, setFinalPrice] = useState(null);

    useEffect(() => {
        if (!isMobile) {
            if (planSelected === 1) {
                firstPlan.current.style.backgroundColor = "#111317";
                secondPlan.current.style.backgroundColor = "";
                free.current.style.background = "";


            } else {
                firstPlan.current.style.backgroundColor = "";
                secondPlan.current.style.backgroundColor = "#111317";
                free.current.style.background = "linear-gradient(#EB233B, #390A11)";
            }
        }
    }, [planSelected])

    const handleSquarMeterAndObjects = (opperation) => {

        if (opperation === '+') {
            if (pricingValue.squarMeter == 1000)
                return
            setPricingValue({
                ...pricingValue,
                squarMeter: Math.min(pricingValue.squarMeter + 100, 1000),
                objects: Math.min(pricingValue.objects + 15, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.objects + 21),

                interactiveMesh: Math.min(pricingValue.interactiveMesh + 2, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMesh + 22),
                interactiveMaterial: Math.min(pricingValue.interactiveMaterial + 2, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMaterial + 22),

            })
        } else {
            if (pricingValue.squarMeter == 100)
                return
            setPricingValue({
                ...pricingValue,
                squarMeter: Math.max(pricingValue.squarMeter - 100, initialValues.squarMeter),
                objects: Math.max(pricingValue.objects - 15, Math.max(((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter) * initialValues.objects, initialValues.objects)),
                interactiveMesh: Math.max(pricingValue.interactiveMesh - 2, Math.max(((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter) * initialValues.interactiveMesh, initialValues.interactiveMesh)),
                interactiveMaterial: Math.max(pricingValue.interactiveMaterial - 2, Math.max(((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter) * initialValues.interactiveMaterial, initialValues.interactiveMaterial)),

            })
        }
    }

    const handleObjects = (opperation) => {
        if (opperation === '+') {
            setPricingValue({
                ...pricingValue,
                objects: Math.min(pricingValue.objects + 1, ((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter + 1) * initialValues.objects + 20),

            })
        } else {
            setPricingValue({
                ...pricingValue,
                objects: Math.max(pricingValue.objects - 1, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.objects),

            })
        }
    }

    const handleInteractiveMesh = (opperation) => {
        if (opperation === '+') {
            setPricingValue({
                ...pricingValue,
                interactiveMesh: Math.min(pricingValue.interactiveMesh + 1, ((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter) * initialValues.interactiveMesh + 22),

            })
        } else {
            setPricingValue({
                ...pricingValue,
                interactiveMesh: Math.max(pricingValue.interactiveMesh - 1, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMesh),

            })
        }
    }
    const handleInteractiveMaterial = (opperation) => {
        if (opperation === '+') {
            setPricingValue({
                ...pricingValue,
                interactiveMaterial: Math.min(pricingValue.interactiveMaterial + 1, ((pricingValue.squarMeter - initialValues.squarMeter) / initialValues.squarMeter) * initialValues.interactiveMaterial + 22),
            })
        } else {
            setPricingValue({
                ...pricingValue,
                interactiveMaterial: Math.max(pricingValue.interactiveMaterial - 1, (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMaterial),
            })
        }
    }

    const handleChoises = (choiceName, choiceValue) => {
        setPricingValue({
            ...pricingValue,
            [choiceName]: choiceValue,
        })
    }


    useEffect(() => {
        let result = 0;
        if (pricingValue.squarMeter < 200) {
            result += pricingValue.squarMeter * 50;
        }
        else if (pricingValue.squarMeter < 250) {
            result += pricingValue.squarMeter * 40;
        } else {
            result += pricingValue.squarMeter * 30;
        }
        if (pricingValue.buildSurroundingArea) {
            result += 2000;
        }
        if (pricingValue.customView) {
            result += 1500;
        }
        result += (pricingValue.objects - (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.objects) * 50;

        result += (pricingValue.interactiveMesh - (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMesh) * 70;
        result += (pricingValue.interactiveMaterial - (pricingValue.squarMeter / initialValues.squarMeter) * initialValues.interactiveMaterial) * 85;
        setFinalPrice(result);
    }, [pricingValue])

    const [email, setEmail] = useState("");

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let interested;
        if (planSelected === 1) {
            interested = {
                ...freePackaging,
                plan: "Free Plan"
            }
        } else {
            interested = {
                ...pricingValue,
                plan: "Custom Plan"
            }
        }
        const data = new FormData();
        data.append('email', email);
        data.append('interested', JSON.stringify(interested));
        const config = {
            method: 'post',
            url: 'https://api.gammastudio.io/api/interested',
            headers: {
                "Content-Type": "application/json"
            },
            data
        }
        axios(config).then((response) => {
            console.log(JSON.stringify(response.data));
            window.open(`https://calendly.com/gammastudio`, '_self')
        })
            .catch((error) => {
                console.error(error);
            })
    }


    return <>
        <motion.div
            className="page__wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ background: "black" }}
        >
            {
                isClicked &&
                <div className="pricing__modal">
                    <div className="pricing__modal--bg" onClick={() => setIsClicked(false)} />
                    <div className="pricing__modal--content">
                        <form onSubmit={handleSubmit}>
                            Please write your email
                            <input required type="email" style={{
                                display: "block",
                                maxWidth: "400px",
                                minWidth: "100px",
                                margin: "1em auto",
                            }}
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <button type="submit" className="pricing__button--continue" style={{
                                display: "block",
                                maxWidth: "400px",
                                minWidth: "100px",
                                margin: "1em auto",
                            }}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            }
            {
                !isMobile ?
                    <section className="pricing">
                        <Link to={"/"} className="pricing__logo">
                            <img src="images/logo.gif" alt="logo" />
                        </Link>
                        <div className="pricing__container">
                            <h1 className="pricing__title">
                                Choose your package
                            </h1>
                            {
                                isMobile &&
                                <h2 className="pricing__subtitle">
                                    This pricing not including streaming fees.
                                </h2>
                            }

                        </div>
                        <div className="pricing__container">

                            <div className="pricing__bg__flex">
                                <div className="pricing__bg__flex--item"></div>
                                <div ref={firstPlan} className="pricing__bg__flex--item"></div>
                                <div ref={secondPlan} className="pricing__bg__flex--item"></div>
                            </div>

                            <div className="pricing__flex" style={{ zIndex: "4" }}>
                                <div className="pricing__item">
                                    {
                                        !isMobile &&
                                        <h2 className="pricing__subtitle">
                                            This pricing not including streaming fees.
                                            <div className="pricing__streaming">
                                                <div className="pricing__free">
                                                    <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                                                    <div className="pricing__free--content">
                                                        <h3>
                                                            120 Min
                                                        </h3>
                                                        <p>$9</p>
                                                    </div>
                                                </div>
                                                <div className="pricing__free">
                                                    <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                                                    <div className="pricing__free--content">
                                                        <h3>
                                                            300 Min
                                                        </h3>
                                                        <p>$24</p>
                                                    </div>
                                                </div>
                                                <div className="pricing__free">
                                                    <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                                                    <div className="pricing__free--content">
                                                        <h3>
                                                            600 Min
                                                        </h3>
                                                        <p>$38</p>
                                                    </div>
                                                </div>
                                                <div className="pricing__free">
                                                    <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                                                    <div className="pricing__free--content">
                                                        <h3>
                                                            1200 Min
                                                        </h3>
                                                        <p>$70</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                    }
                                </div>
                                <div className="pricing__item">
                                    <h3 className="pricing__tab--title">
                                        Free
                                        <br />
                                        Package
                                    </h3>

                                </div>
                                <div className="pricing__item">

                                    <h3 className="pricing__tab--title">
                                        Customise
                                        <br />
                                        Package
                                    </h3>
                                </div>
                            </div>
                            <div className="pricing__flex">
                                <div className="pricing__item">

                                    <div className="pricing__line" />
                                </div>
                                <div className="pricing__item">

                                    <button className="pricing__button" style={{ backgroundColor: planSelected != 1 && "#131313" }} onClick={() => setPlanSelect(1)}>
                                        {
                                            planSelected === 1 ?
                                                "Selected"
                                                :
                                                "Select"
                                        }
                                    </button>
                                </div>
                                <div className="pricing__item">

                                    <button className="pricing__button" style={{ backgroundColor: planSelected != 2 && "#131313" }} onClick={() => setPlanSelect(2)}>
                                        {
                                            planSelected === 2 ?
                                                "Selected"
                                                :
                                                "Select"
                                        }
                                    </button>
                                </div>
                            </div>



                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item">
                                    <p className="pricing__text pricing__text--main">
                                        Square Meter
                                    </p>
                                </div>
                                <div className="pricing__item">
                                    <p className="pricing__number">
                                        200
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__minus" onClick={() => handleSquarMeterAndObjects('-')} />
                                    <p className="pricing__number">
                                        {pricingValue.squarMeter}
                                    </p>
                                    <button className="pricing__plus" onClick={() => handleSquarMeterAndObjects('+')} />
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item">
                                    <p className="pricing__text">
                                        Objects
                                    </p>
                                </div>
                                <div className="pricing__item">
                                    <p className="pricing__number">
                                        15
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__minus" onClick={() => handleObjects('-')} />
                                    <p className="pricing__number">
                                        {pricingValue.objects}
                                    </p>
                                    <button className="pricing__plus" onClick={() => handleObjects('+')} />
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item">
                                    <p className="pricing__text">
                                        Interactive Mesh
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <div className="pricing__cross" />

                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__minus" onClick={() => handleInteractiveMesh('-')} />
                                    <p className="pricing__number">
                                        {pricingValue.interactiveMesh}
                                    </p>
                                    <button className="pricing__plus" onClick={() => handleInteractiveMesh('+')} />
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item ">
                                    <p className="pricing__text">
                                        Interactive Materials
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <div className="pricing__cross" />
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__minus" onClick={() => handleInteractiveMaterial('-')} />
                                    <p className="pricing__number">
                                        {pricingValue.interactiveMaterial}
                                    </p>
                                    <button className="pricing__plus" onClick={() => handleInteractiveMaterial('+')} />
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item ">
                                    <p className="pricing__text">
                                        Build surrouding area
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <div className="pricing__cross" />
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__choice" onClick={() => handleChoises("buildSurroundingArea", true)} style={{ opacity: !pricingValue.buildSurroundingArea && "0.5" }}>
                                        YES
                                    </button>
                                    <button className="pricing__choice" onClick={() => handleChoises("buildSurroundingArea", false)} style={{ opacity: pricingValue.buildSurroundingArea && "0.5" }}>
                                        NO
                                    </button>
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item ">
                                    <p className="pricing__text">
                                        Custom view
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <div className="pricing__cross" />
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__choice" onClick={() => handleChoises("customView", true)} style={{ opacity: !pricingValue.customView && "0.5" }}>
                                        YES
                                    </button>
                                    <button className="pricing__choice" onClick={() => handleChoises("customView", false)} style={{ opacity: pricingValue.customView && "0.5" }}>
                                        NO
                                    </button>
                                </div>
                            </div>
                            <div className="pricing__flex pricing__border">
                                <div className="pricing__item ">
                                    <p className="pricing__text">
                                        Business listing
                                        <span className="pricing__comingSoon">
                                            Coming soon
                                        </span>
                                    </p>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <div className="pricing__cross" />
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    <button className="pricing__choice" onClick={() => handleChoises("businessListing", false)} style={{ opacity: "0.5" }}>
                                        YES
                                    </button>
                                    <button className="pricing__choice" onClick={() => handleChoises("businessListing", false)} style={{ opacity: "0.5" }}>
                                        NO
                                    </button>
                                </div>
                            </div>
                            <div className="pricing__flex pricing__final--price">
                                <div className="pricing__item ">
                                    <div ref={free} className="pricing__free">
                                        <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                                        <div className="pricing__free--content">
                                            <h3>
                                                Free 1000 Min
                                            </h3>
                                            <p>Streaming</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    $0
                                </div>
                                <div className="pricing__item pricing__item--flex">
                                    ${finalPrice}
                                </div>
                            </div>


                        </div>
                        <div className="pricing__container">

                            <div className="pricing__flex">
                                <div className="pricing__item ">

                                </div>
                                <div className="pricing__item">
                                    <button className="pricing__button--continue" onClick={() => setIsClicked(true)}>
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <PricingMobile
                        pricingValue={pricingValue}
                        handleSquarMeterAndObjects={handleSquarMeterAndObjects}
                        handleObjects={handleObjects}
                        handleInteractiveMesh={handleInteractiveMesh}
                        handleInteractiveMaterial={handleInteractiveMaterial}
                        handleChoises={handleChoises}
                        finalPrice={finalPrice}
                        setIsClicked={setIsClicked}
                        setBigPlanSelect={setPlanSelect}
                    />
            }

        </motion.div>
    </>
}