import { motion } from "framer-motion";

import Header from "./Header.jsx";

import Hero from "./Home/Hero.jsx";
import Services from "./Home/Services.jsx";
import Showcases from "./Home/Showcases.jsx";
import Clients from "./Home/Clients.jsx";
import Partners from "./Home/Partners.jsx";
import Contact from "./Home/Contact.jsx";
import Footer from "./Footer.jsx";

export default function Home() {
    window.scrollTo(0, 0);

    return (
        <motion.div
            className="page__wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Header />
            <Hero />
            <Services />
            <Showcases />
            <Clients />
            <Partners />
            <Contact />
            <Footer />
        </motion.div>
    );
}
