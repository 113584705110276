import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ inAboutUs = false }) {
    const [link, setLink] = useState("images/logo.gif");

    return <>
        <div className="header">
            <Link to={"/"} className="header__logo">
                {/* <img src="images/logo.png" alt="logo" /> */}
                <img src={link} alt="logo" />
                {/* <video src="videos/logo.mp4"></video> */}
            </Link>
            <div className="header__links">
            <Link to={"/pricing"} className="header__button">
                    Pricing
                </Link>
            {
                !inAboutUs && <Link to={"/about"} className="header__button">
                    About us
                </Link>
            }
            </div>
        </div>
    </>
}