import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function PricingMobile({ pricingValue, handleSquarMeterAndObjects, handleObjects, handleInteractiveMesh, handleInteractiveMaterial, handleChoises, finalPrice, setIsClicked, setBigPlanSelect }) {
    const [planSelected, setPlanSelected] = useState(2);
    const firstPlan = useRef();
    const secondPlan = useRef();
    const free = useRef();

    const handleClick = (plan) => {
        setPlanSelected(plan);
        setBigPlanSelect(plan);
    }

    useEffect(()=>{
        if( planSelected === 1 ){
            firstPlan.current.style.border = "1px solid red";
            secondPlan.current.style.border = "";
            free.current.style.background = "";
            
        } else {
            secondPlan.current.style.border = "1px solid red";
            firstPlan.current.style.border = "";
            free.current.style.background = "linear-gradient(#EB233B, #390A11)";

        }
    }, [planSelected])

    return <>
        <section className="pricingMobile">
            <Link to={"/"} className="pricingMobile__logo">
                <img src="images/logo.gif" alt="logo" />
            </Link>
            <h1 className="pricingMobile__title">
                Choose your package
            </h1>
            <h2 className="pricingMobile__subtitle">
                This pricing not including streaming fees.
            </h2>

            <div ref={firstPlan} onClick={()=>handleClick(1)} className="pricingMobile__plan">
                <h3 className="pricingMobile__plan--title">
                    Free Package
                </h3>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Square Meter
                    </p>
                    <p className="pricingMobile__number">
                        200
                    </p>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Objects
                    </p>
                    <p className="pricingMobile__number">
                        15
                    </p>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Interactive Mesh
                    </p>
                    <div className="pricingMobile__cross">
                        <div className="pricing__cross" />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Interactive Materials
                    </p>
                    <div className="pricingMobile__cross">
                        <div className="pricing__cross" />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Build surrouding area
                    </p>
                    <div className="pricingMobile__cross">
                        <div className="pricing__cross" />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Custom view
                    </p>
                    <div className="pricingMobile__cross">
                        <div className="pricing__cross" />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Business listing
                    </p>
                    <div className="pricingMobile__cross">
                        <div className="pricing__cross" />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                    
                    </p>
                    <div className="pricingMobile__result">
                        0$
                    </div>
                </div>
            </div>

            <div ref={secondPlan} onClick={()=>handleClick(2)} className="pricingMobile__plan">
                <h3 className="pricingMobile__plan--title">
                    Custom Package
                </h3>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Square Meter
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__minus" onClick={() => handleSquarMeterAndObjects('-')} />
                        <p className="pricing__number">
                            {pricingValue.squarMeter}
                        </p>
                        <button className="pricing__plus" onClick={() => handleSquarMeterAndObjects('+')} />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Objects
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__minus" onClick={() => handleObjects('-')} />
                        <p className="pricing__number">
                            {pricingValue.objects}
                        </p>
                        <button className="pricing__plus" onClick={() => handleObjects('+')} />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Interactive Mesh
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__minus" onClick={() => handleInteractiveMesh('-')} />
                        <p className="pricing__number">
                            {pricingValue.interactiveMesh}
                        </p>
                        <button className="pricing__plus" onClick={() => handleInteractiveMesh('+')} />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Interactive Materials
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__minus" onClick={() => handleInteractiveMaterial('-')} />
                        <p className="pricing__number">
                            {pricingValue.interactiveMaterial}
                        </p>
                        <button className="pricing__plus" onClick={() => handleInteractiveMaterial('+')} />
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Build surrouding area
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__choice" onClick={() => handleChoises("buildSurroundingArea", true)} style={{ opacity: !pricingValue.buildSurroundingArea && "0.5" }}>
                            YES
                        </button>
                        <button className="pricing__choice" onClick={() => handleChoises("buildSurroundingArea", false)} style={{ opacity: pricingValue.buildSurroundingArea && "0.5" }}>
                            NO
                        </button>
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Custom view
                    </p>
                    <div className="pricing__item pricing__item--flex">
                        <button className="pricing__choice" onClick={() => handleChoises("customView", true)} style={{ opacity: !pricingValue.customView && "0.5" }}>
                            YES
                        </button>
                        <button className="pricing__choice" onClick={() => handleChoises("customView", false)} style={{ opacity: pricingValue.customView && "0.5" }}>
                            NO
                        </button>
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        Business listing
                        <span className="pricing__comingSoon">
                                            Coming soon
                                        </span>
                    </p>
                    <div className="pricing__item--flex">
                        <button className="pricing__choice" onClick={() => handleChoises("businessListing", false)} style={{ opacity: "0.5" }}>
                            YES
                        </button>
                        <button className="pricing__choice" onClick={() => handleChoises("businessListing", false)} style={{ opacity:  "0.5" }}>
                            NO
                        </button>
                    </div>
                </div>
                <div className="pricingMobile__flex">
                    <p className="pricingMobile__text">
                        
                    </p>
                    <div className="pricingMobile__result">
                    ${finalPrice}
                    </div>
                </div>
                
            </div>


            <button className="pricing__button--continue" onClick={()=>{setIsClicked(true)}}>
                Continue
            </button>

            <div ref={free} className="pricing__free">
                <img src="images/gpu.png" alt="gpu icon" className="pricing__free--image" />
                <div className="pricing__free--content">
                    <h3>
                        Free 1000 Min
                    </h3>
                    <p>Streaming</p>
                </div>
            </div>

        </section>
    </>
}